<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-tep-preliquidacion-export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Reporte Preliquidaciones
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-12">
                      <label># Viaje</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filtros.id"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label>Transportadora</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="transportadora"
                        placeholder="Razon social"
                        label="razon_social"
                        :options="listasForms.transportadoras"
                        @input="seleccionarTransportadora()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Ruta</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="ruta"
                        placeholder="Ruta"
                        label="nombre"
                        :options="listasForms.rutas"
                        @input="seleccionarRuta()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="estado">Estado</label>
                      <select
                        id="estado"
                        v-model="filtros.estado"
                        class="form-control form-control-sm"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Tipo de Vehículo</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="tipo_vehiculo"
                        placeholder="Tipo Vehículo"
                        label="nombre"
                        :options="listasForms.tipos_vehiculos"
                        @input="seleccionarTipoVehiculo()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Vehículo</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="vehiculo"
                        placeholder="Placa"
                        label="placa"
                        :options="listasForms.vehiculos"
                        @input="seleccionarVehiculo()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Conductor</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="conductor"
                        placeholder="Conductror"
                        label="nConductor"
                        :options="listasForms.conductores"
                        @input="seleccionarConductor()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Desde</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_ini"
                        @input="validaFechasFiltro()"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label>Hasta</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_fin"
                        @input="validaFechasFiltro()"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="
                  $store.getters.can('tep.preliquidacionesNacionales.export')
                "
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
export default {
  name: "TepPreliquidacionNacionalExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      filtros: {
        id: null,
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        tipo_operacion: null,
        operacion_id: null,
        estado: null,
        tipo_vehiculo_id: null,
        vehiculo_id: null,
        conductor_id: null,
        ruta: null,
      },
      listasForms: {
        transportadoras: [],
        tipos_vehiculos: [],
        vehiculos: [],
        conductores: [],
        tipos_operaciones: [],
        operaciones: [],
        rutas: [],
        estados: [],
      },
      transportadora: null,
      tipo_vehiculo: null,
      vehiculo: null,
      conductor: null,
      tipo_operacion: null,
      operacion: null,
      fecha_ini: null,
      fecha_fin: null,
      ruta: null,
    };
  },

  methods: {
    getTransportadora() {
      let me = this;
      axios
        .get("api/admin/empresas/listaTep?estado=1")
        .then(function(response) {
          me.listasForms.transportadoras = response.data;
        });
    },

    async getEstados() {
      await axios.get("/api/lista/98").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    seleccionarTransportadora() {
      if (this.transportadora != null) {
        this.filtros.empresa_id = this.transportadora.id;
      } else {
        this.filtros.empresa_id = null;
      }
    },

    getTiposVehiculos(tipo_vehiculos) {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipos_vehiculos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarTipoVehiculo() {
      if (this.tipo_vehiculo != null) {
        this.filtros.tipo_vehiculo_id = this.tipo_vehiculo.id;
      } else {
        this.filtros.tipo_vehiculo_id = null;
      }
    },

    getVehiculos() {
      let me = this;
      axios.get("/api/admin/vehiculos/lista").then((response) => {
        me.listasForms.vehiculos = response.data;
      });
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
    },

    getConductores() {
      let me = this;
      axios.get("/api/admin/conductores/lista").then((response) => {
        me.listasForms.conductores = response.data;
      });
    },

    seleccionarConductor() {
      if (this.conductor != null) {
        this.filtros.conductor_id = this.conductor.id;
      } else {
        this.filtros.conductor_id = null;
      }
    },

    getTipoOperacion() {
      let me = this;
      axios.get("/api/lista/80").then((response) => {
        me.listasForms.tipos_operaciones = response.data;
      });
    },

    seleccionarTipoOperacion() {
      if (this.tipo_operacion != null) {
        this.filtros.tipo_operacion = this.tipo_operacion.numeracion;
      } else {
        this.filtros.tipo_operacion = null;
      }
    },

    getOperaciones() {
      let me = this;
      axios.get("/api/admin/sitios/lista").then((response) => {
        me.listasForms.operaciones = response.data;
      });
    },

    seleccionarOperacion() {
      if (this.operacion != null) {
        this.filtros.operacion_id = this.operacion.id;
      } else {
        this.filtros.operacion_id = null;
      }
    },

    getRuta() {
      let me = this;
      axios.get("/api/tep/Ruta/lista").then((response) => {
        me.listasForms.rutas = response.data;
      });
    },

    seleccionarRuta() {
      if (this.ruta != null) {
        this.filtros.ruta = this.ruta.id;
      } else {
        this.filtros.ruta = null;
      }
    },

    validaFechasFiltro() {
      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        this.validaFechas(this.filtros.fecha_ini, this.filtros.fecha_fin);
      }
    },

    validaFechas(fecha_menor, fecha_mayor) {
      fecha_menor = new Date(fecha_menor);
      fecha_mayor = new Date(fecha_mayor);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicial no puede ser mayor a la fecha final...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/preliquidacionesNacionales/export",
        data: this.filtros,
      })
        .then((response) => {
          if (!response.data.error) {
            let data = response.data;
            location.href = data.url;
          } else {
            this.$swal({
              icon: "error",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3500,
              timerProgressBar: true,
            });
          }
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    this.getTransportadora();
    this.getTiposVehiculos();
    this.getVehiculos();
    this.getConductores();
    this.getTipoOperacion();
    this.getOperaciones();
    this.getEstados();
    this.getRuta();
    this.cargando = false;
  },
};
</script>
